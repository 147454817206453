import React, { PropsWithChildren } from 'react';
import { Step, Steps } from 'intro.js-react';
import { defineStorageMethod } from '@lo/shared/helpers/defineStorageMethod';
import { ADD_TO_STORAGE } from '@lo/shared/constants';
import { useTranslation } from 'react-i18next';
import { useStorageValue } from '../../hooks/useStorageValue';
import { getBooleanFromString } from '@lo/shared/helpers/string/getBooleanFromString';
import useRestaurant from '@lo/shared/hooks/useRestaurant';

type TutorialProps = {
    id: string;
    enabled: boolean;
    steps?: Step[];
    scrollToElement?: boolean;
};

const Tutorial: React.FC<PropsWithChildren<TutorialProps>> = ({ id, enabled, steps, scrollToElement = true }) => {
    const { t, ready } = useTranslation();
    const restaurant = useRestaurant();
    const tutorialsEnabled = restaurant.ui_settings.enable_tutorials;
    const seenTutorials = useStorageValue('seenTutorials')?.split(',') || [];
    const isOnboardingVisible = getBooleanFromString(useStorageValue('isOnboardingVisible') || 'false');
    const showTutorial = ready && enabled && tutorialsEnabled && !seenTutorials?.includes(id) && !isOnboardingVisible;

    const handleTutorialFinish = (): void => {
        seenTutorials.push(id);
        defineStorageMethod(ADD_TO_STORAGE, 'seenTutorials', [...new Set(seenTutorials)].join(','));
    };

    if (!steps) return null;

    return (
        <Steps
            enabled={showTutorial}
            initialStep={0}
            steps={steps}
            onExit={handleTutorialFinish}
            options={{
                prevLabel: t('orders.live_orders_tutorials.buttons.back'),
                nextLabel: t('orders.live_orders_tutorials.buttons.next'),
                doneLabel: t('orders.live_orders_tutorials.buttons.done'),
                scrollToElement,
                scrollTo: 'tooltip',
                disableInteraction: true,
                hidePrev: true,
                showBullets: steps.length > 1,
                highlightClass: 'tutorial-highlight',
                tooltipClass: 'tutorial-tooltip',
                buttonClass: 'tutorial-btn'
            }}
        />
    );
};

export default Tutorial;
