const retryOnFailure = async <T>(callback: () => Promise<T>, maxRetries = 2): Promise<T> => {
    let retries = 0;
    let error = null;

    while (retries < maxRetries) {
        try {
            return await callback();
        } catch (e) {
            error = e;
            retries++;
        }
    }

    throw error;
};

export default retryOnFailure;
