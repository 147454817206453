import React, { useEffect, useState } from 'react';
import useNotifications from '@lo/shared/hooks/useNotifications';
import classes from './NotificationsSidebar.module.scss';
import RepeaterContainer from '../UI/RepeaterContainer/RepeaterContainer';
import Notification from '../Notification/Notification';
import NotificationSkeleton from './NotificationSkeleton/NotificationSkeleton';
import { useTranslation } from 'react-i18next';
import Tutorial from '../Tutorial/Tutorial';
import useRestaurant from '@lo/shared/hooks/useRestaurant';
import { SideSheet } from '@jet-pie/react';
import NotificationsHeader from './NotificationsHeader/NotificationsHeader';
import { useOrderNotifications } from '@lo/shared/store/orderNotifications';
import compareAsc from 'date-fns/compareAsc';
import compareDesc from 'date-fns/compareDesc';
import upToDateImage from '../../static/images/up-to-date.png';
import {
    prepareGeneralNotificationsData,
    prepareOrderNotificationsData
} from '@lo/shared/helpers/notification/orderNotification';
import { useWindowSize } from '../../hooks/useWindowSize';
import { useTutorial } from '../../hooks/useTutorial';

type NotificationsSidebarProps = {
    isOpen: boolean;
    toggleSidebar: () => void;
};

const NotificationsSidebar: React.FC<NotificationsSidebarProps> = ({ isOpen, toggleSidebar }) => {
    const { t } = useTranslation();
    const { isLessThanTabletWidth } = useWindowSize();
    const { data, isFetching } = useNotifications();
    const orderNotifications = useOrderNotifications();
    const restaurant = useRestaurant();

    const [showTutorial, setShowTutorial] = useState(false);
    const [selectedTab, setSelectedTab] = useState<'urgent' | 'all'>('urgent');
    const [sorting, setSorting] = useState<'asc' | 'desc'>('asc');
    const { getTutorialRef, ...tutorial } = useTutorial('notifications');

    const notifications = [
        ...prepareOrderNotificationsData(orderNotifications, restaurant.reference),
        ...(data ? prepareGeneralNotificationsData(data, restaurant.timezone) : [])
    ].sort((itemA, itemB) =>
        sorting === 'asc' ? compareAsc(itemA.createdAt, itemB.createdAt) : compareDesc(itemA.createdAt, itemB.createdAt)
    );
    const urgentNotifications = notifications.filter((item) => item.level === 'warning');
    const isUrgentTabHidden = urgentNotifications.length === 0;

    useEffect(() => {
        if (isOpen) {
            // Have to wait before element finish sliding in
            setTimeout(() => setShowTutorial(true), 500);
        }
    }, [isOpen]);

    useEffect(() => {
        if (isUrgentTabHidden) {
            setSelectedTab('all');
        } else {
            setSelectedTab('urgent');
        }
    }, [isUrgentTabHidden, isOpen]);

    return (
        <SideSheet
            id="notificationsSideSheet"
            data-testid="notifications-sidebar"
            isOpen={isOpen}
            onShowSideSheet={toggleSidebar}
            orientation="right"
            width={isLessThanTabletWidth ? '100%' : '490px'}
            hideOnOutsideClick
            hideHeader
            backdrop
        >
            <div className={classes.container} ref={getTutorialRef()}>
                <NotificationsHeader
                    onClose={toggleSidebar}
                    selectedTab={selectedTab}
                    sorting={sorting}
                    isUrgentTabHidden={isUrgentTabHidden}
                    notificationsCounter={notifications.length}
                    urgentNotificationsCounter={urgentNotifications.length}
                    onTabChange={setSelectedTab}
                    onSortingChange={setSorting}
                />

                {showTutorial && <Tutorial {...tutorial} />}

                {isFetching && (
                    <RepeaterContainer times={3}>
                        <NotificationSkeleton />
                    </RepeaterContainer>
                )}

                {notifications.length > 0 && restaurant.timezone ? (
                    <div className={classes.list} data-testid="notifications-container">
                        {(selectedTab === 'urgent' ? urgentNotifications : notifications).map((notification) => (
                            <Notification key={notification.id} onClose={toggleSidebar} notification={notification} />
                        ))}
                    </div>
                ) : (
                    <div data-testid="empty-notifications-container" className={classes.emptyContainer}>
                        <img className={classes.upToDateImage} src={upToDateImage} />
                        <p className={classes.emptyTitle}>{t('orders.live_orders_notifications.main.no_notifications_title')}</p>
                        <p className={classes.emptyDescription}>
                            {t('orders.live_orders_notifications.main.no_notifications_description')}
                        </p>
                    </div>
                )}
            </div>
        </SideSheet>
    );
};

export default NotificationsSidebar;
