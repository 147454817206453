import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@jet-pie/react';
import { CookiePolicy } from '@jet-pie/react/dist/icons';
import { REMOVE_FROM_STORAGE } from '@lo/shared/constants';
import { defineStorageMethod } from '@lo/shared/helpers/defineStorageMethod';
import { useAcceptedCookies, useCookiesActions } from '@lo/shared/store/cookies';
import { useWindowSize } from '../../hooks/useWindowSize';
import classes from './CookieBanner.module.scss';

const CookieBanner: React.FC = () => {
    const accepted = useAcceptedCookies();
    const { accept } = useCookiesActions();
    const { isLessThanTabletWidth } = useWindowSize();
    const { t } = useTranslation();

    useEffect(() => {
        // Remove old value
        defineStorageMethod(REMOVE_FROM_STORAGE, 'cookieBannerWasSeen');
    }, []);

    return accepted === null ? (
        <div className={classes.banner}>
            <div className={classes.header}>
                <CookiePolicy />
                {t('orders.live_orders_terms.cookie_banner.cookies')}
            </div>

            <div
                className={classes.message}
                dangerouslySetInnerHTML={{
                    __html: t('orders.live_orders_terms.cookie_banner.message', {
                        statementLink: `<a href="/cookie-statement" target="__blank">${t(
                            'orders.live_orders_terms.cookie_banner.statement'
                        )}</a>`
                    }) as string
                }}
                data-testid="cookie-banner-message"
            />

            <div className={classes.actions}>
                <Button
                    onClick={() => accept('necessary')}
                    variant="primary"
                    size={isLessThanTabletWidth ? 'xSmall' : 'small-expressive'}
                    data-testid="cookie-banner-necessary-button"
                    className={classes.button}
                >
                    {t('orders.live_orders_terms.cookie_banner.necessary_only')}
                </Button>
                <Button
                    onClick={() => accept('all')}
                    variant="primary"
                    size={isLessThanTabletWidth ? 'xSmall' : 'small-expressive'}
                    data-testid="cookie-banner-accept-button"
                    className={classes.button}
                >
                    {t('orders.live_orders_terms.cookie_banner.accept')}
                </Button>
            </div>
        </div>
    ) : null;
};

export default CookieBanner;
