import React, { FC, Suspense, lazy, useEffect } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { useHistory } from 'react-router';
import { Spinner } from '@jet-pie/react';
import retryOnFailure from '@lo/shared/helpers/retryOnFailure';
import analytics from '@lo/shared/services/analytics';
import PrivateLayout from '../layouts/PrivateLayout/PrivateLayout';
import StatementLayout from '../layouts/StatementLayout/StatementLayout';
import PrivateRoute from './PrivateRoute';

const TConnect = lazy(() => retryOnFailure(() => import('../pages/TConnect/TConnect')));
const NotFound = lazy(() => retryOnFailure(() => import('../pages/NotFound/NotFound')));
const Receipt = lazy(() => retryOnFailure(() => import('../pages/Receipt/Receipt')));
const CookieStatement = lazy(() => retryOnFailure(() => import('../pages/CookieStatement/CookieStatement')));
const TechnologiesList = lazy(() => import('../pages/TechnologiesList/TechnologiesList'));
const Orders = lazy(() => retryOnFailure(() => import('../pages/Orders')));
const Settings = lazy(() => retryOnFailure(() => import('../pages/Settings/Settings')));
const Menu = lazy(() => retryOnFailure(() => import('../pages/Menu')));
const OrderHistory = lazy(() => retryOnFailure(() => import('../pages/OrderHistory')));

const Loader: FC = () => (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <Spinner variant="brand" size="XL" />
    </div>
);

export const Routes: React.FC = () => {
    const history = useHistory();

    useEffect(() => {
        analytics.viewedPage();
    }, [history.location.pathname]);

    return (
        <Suspense fallback={<Loader />}>
            <Switch>
                <Route exact path="/">
                    <Redirect to={`/orders${history.location.search}`} />
                </Route>

                <Route path={['/cookie-statement', '/technologies-list']} exact>
                    <StatementLayout>
                        <Route path="/cookie-statement" component={CookieStatement} />
                        <Route path="/technologies-list" component={TechnologiesList} />
                    </StatementLayout>
                </Route>

                <Route path="/tconnect" component={TConnect} />

                <PrivateRoute path={['/orders/:id/receipt']} component={Receipt} />

                <PrivateRoute loader={<Loader />} path={['/orders', '/history', '/settings', '/menu']}>
                    <PrivateLayout>
                        <Suspense fallback={<Loader />}>
                            <Switch>
                                <Route path="/orders" component={Orders} />
                                <Route path="/history" component={OrderHistory} />
                                <Route path="/settings" component={Settings} />
                                <Route path="/menu" component={Menu} />
                            </Switch>
                        </Suspense>
                    </PrivateLayout>
                </PrivateRoute>

                <Route component={NotFound} />
            </Switch>
        </Suspense>
    );
};
