import React, { PropsWithChildren } from 'react';
import { RouteProps, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import classes from './StatementLayout.module.scss';

const StatementLayout: React.FC<PropsWithChildren<RouteProps>> = (props) => {
    const history = useHistory();
    const { t } = useTranslation();

    return (
        <div className={classes.container}>
            <div className={classes.wrapper}>
                <button className={classes.goBackButton} onClick={() => history.push('/orders')}>
                    {t('orders.live_orders_receipt.main.back_to_orders')}
                </button>

                {props.children}
            </div>
        </div>
    );
};

export default StatementLayout;
