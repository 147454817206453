/* eslint-disable no-process-env */

export default {
    env: process.env.ENVIRONMENT || process.env.NODE_ENV,
    apiUrl: process.env.API_URL ?? '',
    socketHost: process.env.SOCKET_HOST ?? '',
    oktaTokenIssuer: process.env.OKTA_TOKEN_ISSUER ?? '',
    fcmVapidKey: process.env.FCM_VAPID_KEY ?? '',
    partnerHubUrl: process.env.PARTNER_HUB ?? '',
    jetFmObfuscationKey: process.env.JET_FM_OBFUSCATION_KEY ?? '',
    release: process.env.RELEASE,
    codePushKeyIos: null,
    codePushKeyAndroid: null,
    keycloakUrl: process.env.KEYCLOAK_URL ?? '',
    keycloakInternalUrl: process.env.KEYCLOAK_INTERNAL_URL ?? ''
};
